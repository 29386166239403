import { defineStore } from 'pinia';
import { COLLECTION_IDS } from 'mpu-shared-lib';
import { IAddress, IAddressInstance } from '@/types/address';
import { useAppwrite } from '../utils/appwrite';
import { BaseStoreState } from './types';
import { Query } from 'appwrite';

export const getNewAddress = (): IAddress => ({
  city: '',
  houseNumber: '',
  street: '',
  zipCode: '',
});

const { databases, omitDocumentKeys } = useAppwrite();

export const useAddressStore = defineStore('AddressStore', {
  state: (): BaseStoreState<IAddressInstance> => ({
    data: [],
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    async create(address: IAddress) {
      const result = await databases.createDocument(
        'MpuDatabase',
        COLLECTION_IDS.ADDRESS_COLLECTION,
        'unique()',
        address,
      );

      this.data.push({
        ...address,
        ...result,
      });

      return result.$id;
    },

    async loadAll(offset?: number) {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.ADDRESS_COLLECTION,
        [Query.limit(100), Query.offset(offset ?? 0)],
      );
      const documents = result.documents as unknown as IAddressInstance[];

      if (offset) {
        this.data.push(...documents);
      } else {
        this.data = documents;
      }

      if (result.total > this.data.length) {
        const newOffset =
          offset !== undefined ? offset + documents.length : 100;

        this.loadAll(newOffset);
      }
    },

    async loadSingle(id: string) {
      this.selected = (await databases.getDocument(
        'MpuDatabase',
        COLLECTION_IDS.ADDRESS_COLLECTION,
        id,
      )) as unknown as IAddressInstance;
    },

    async update(id: string, address: IAddress) {
      await databases.updateDocument(
        'MpuDatabase',
        COLLECTION_IDS.ADDRESS_COLLECTION,
        id,
        omitDocumentKeys(address),
      );
      this.loadAll();
    },
  },

  getters: {
    getById: (state) => {
      return (id: string): IAddressInstance | null =>
        state.data.find((address) => address.$id === id) || null;
    },
  },
});
