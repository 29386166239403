import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import type { App, ComponentPublicInstance } from 'vue';
import type { Router } from 'vue-router';

// needed for type cast as Sentry doesn't export the type
// and the Router type is not compatible currently.
type VueRouter = Parameters<typeof Sentry.vueRouterInstrumentation>[0];

export const getSentryTransactionRate = (): number => {
  try {
    const transactionRate = Number.parseFloat(
      import.meta.env.VITE_APP_SENTRY_TRANSACTION || '1.0',
    );
    if (Number.isNaN(transactionRate)) {
      return 1;
    }
    return transactionRate;
  } catch {
    return 1;
  }
};

export const initSentry = (app: App, router: Router): void => {
  if (import.meta.env.DEV) {
    return;
  }

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN || '',
    environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT || 'local',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          router as VueRouter,
        ),
        tracingOrigins: ['mpu.nordic-web-solutions.de'],
      }),
    ],
    tracesSampleRate: getSentryTransactionRate(),
    logErrors: import.meta.env.VITE_APP_SENTRY_LOG_ERRORS === 'true',
    // level of serialization of send data
    normalizeDepth: 6,

    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release || undefined,
    dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist || undefined,
  });
};

export const sendErrorToSentry = (
  error: unknown,
  info: string,
  instance?: ComponentPublicInstance | null,
) => {
  if (import.meta.env.DEV) {
    return;
  }

  Sentry.withScope((scope) => {
    if (error instanceof AxiosError) {
      scope.setExtra('axios-request', error.request);
      scope.setExtra('axios-response', error.response);
    }

    scope.setExtras({
      info: info,
      timestamp: Date.now(),
      instance: instance,
    });

    Sentry.captureException(error);
  });
};
