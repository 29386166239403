import {
  Client,
  Databases,
  Account,
  Storage,
  Functions,
  Teams,
} from 'appwrite';
import {omit} from 'lodash-es';

let client: Client;
let databases: Databases;
let account: Account;
let storage: Storage;
let functions: Functions;
let teams: Teams;

export const useAppwrite = () => {
  if (client === undefined) {
    client = new Client();

    client
      .setEndpoint(import.meta.env['VITE_APP_BACKEND_HOST'] as string)
      .setProject(import.meta.env['VITE_APP_PROJECT_ID'] as string);

    databases = new Databases(client);
    account = new Account(client);
    storage = new Storage(client);
    functions = new Functions(client);
    teams = new Teams(client);
  }

  const executeFunction = async <T>(id: string, data?: any): Promise<T> => {
    const stringData = JSON.stringify(data);
    const result = await functions.createExecution(id, stringData, false);

    return JSON.parse(result.response);
  };

  return {
    client,
    databases,
    account,
    storage,
    functions,
    teams,
    executeFunction,

    omitDocumentKeys: (record: any) =>
      omit(record, [
        '$id',
        '$createdAt',
        '$updatedAt',
        '$permissions',
        '$collectionId',
        '$databaseId',
      ]),
  };
};
