import { SelectOption } from 'naive-ui';
import { defineStore } from 'pinia';
import { COLLECTION_IDS } from 'mpu-shared-lib';
import {
  IClient,
  IClientInstance,
  IClientInstanceWithRecords,
} from '../types/client';
import { useAppwrite } from '../utils/appwrite';
import { BaseStoreState } from './types';
import { IAddress } from '@/types/address';
import { useAddressStore } from './address';
import { useClientRecordStore } from './clientRecord';
import { orderBy } from 'lodash-es';
import { Query } from 'appwrite';

export const getNewClient = (): IClient => ({
  firstName: '',
  lastName: '',
  birthdate: null,
  cellphoneNumber: null,
  children: false,
  graduation: null,
  landlineNumber: null,
  mailAddress: null,
  maritalStatus: null,
  other: null,
  placeOfBirth: null,
  profession: null,
  training: null,
  status: '',
});

const { databases, omitDocumentKeys } = useAppwrite();

export const useClientStore = defineStore('ClientStore', {
  state: (): BaseStoreState<IClientInstance> => ({
    data: [],
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    async create(client: IClient, address: IAddress) {
      const addressId = await useAddressStore().create(address);

      const result = await databases.createDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_COLLECTION,
        'unique()',
        {
          ...client,
          addressId,
        },
      );

      this.data.push({
        ...client,
        addressId: addressId,
        ...result,
      });

      return result.$id;
    },

    async loadAll(offset?: number) {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_COLLECTION,
        [Query.limit(100), Query.offset(offset ?? 0)],
      );

      const documents = result.documents as unknown as IClientInstance[];

      if (offset) {
        this.data.push(...documents);
      } else {
        this.data = documents;
      }

      if (result.total > this.data.length) {
        const newOffset =
          offset !== undefined ? offset + documents.length : 100;

        this.loadAll(newOffset);
      }
    },

    async loadSingle(id: string) {
      const client = (await databases.getDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_COLLECTION,
        id,
      )) as unknown as IClientInstance;

      const index = this.data.findIndex(
        (oldClient) => oldClient.$id === client.$id,
      );

      this.data[index] = client;
      this.selected = client;
    },

    async update(
      clientId: string,
      client: IClient,
      addressId: string,
      address: IAddress,
    ) {
      await databases.updateDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_COLLECTION,
        clientId,
        omitDocumentKeys(client),
      );

      await useAddressStore().update(addressId, address);
    },

    async delete(id: string) {
      const result = await databases.deleteDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_COLLECTION,
        id,
      );

      this.data = this.data.filter((record) => record.$id !== id);
    },
  },

  getters: {
    clientSelect: (state): SelectOption[] => {
      const result: SelectOption[] = [];

      state.data.forEach((client) => {
        result.push({
          label: `${client.firstName} ${client.lastName}`,
          value: client.$id,
        });
      });

      return orderBy(
        result,
        [(val) => (val.label as string).toLocaleLowerCase()],
        ['asc'],
      );
    },

    clientsWithRecords: (state): IClientInstanceWithRecords[] => {
      const result: IClientInstanceWithRecords[] = [];

      state.data.forEach((client) => {
        const records = useClientRecordStore().byClientId[client.$id] || [];
        result.push({ ...client, records });
      });

      return result;
    },

    getById:
      (state): ((id: string) => IClientInstance | undefined) =>
      (id: string) =>
        state.data.find((client) => client.$id === id),
  },
});
