import { SelectOption } from 'naive-ui';
import { defineStore } from 'pinia';
import { COLLECTION_IDS, FUNCTION_IDS } from 'mpu-shared-lib';
import { IClientRecord, IClientRecordInstance } from '../types/clientRecord';
import { useAppwrite } from '../utils/appwrite';
import { BaseStoreState } from './types';
import { Query, Permission, Role } from 'appwrite';

export const getNewClientRecord = (): IClientRecord => ({
  firstAppointment: undefined,
  fee: 0,
  recommendation: '',
  consultation: '',
  therapy: '',
  supportGroup: '',
  detoxification: '',
  hospitalStay: '',
  detention: '',
  alcoholConsummation: '',
  drugConsummation: '',
  illness: '',
  medication: '',
  clerk: '',
  clerkNumberExtension: '',
  clerkFaxNumber: '',
  fileNumber: '',
  clientId: '',
  licenseAuthorityId: '',
  licenseFile: false,
  licenseFileDate: undefined,
  fahreigungsRegister: false,
  fahreigungsRegisterDate: undefined,
  laborBefunde: false,
  laborBefundeArt: '',
  laborBefundeZeitraum: '',
  laborBefundeDate: undefined,
  userZugewiesen: false,
  neuerteilungsAntrag: false,
  neuerteilungsAntragDate: undefined,
  begutachtungsStelle: false,
  mpuTermin: false,
});

const { databases, omitDocumentKeys, executeFunction } = useAppwrite();

interface StoreState {
  data: IClientRecordInstance[];
  selected: IClientRecordInstance | IClientRecord | null;
  isLoading: boolean;
  error: null | string;
}

export const useClientRecordStore = defineStore('ClientRecordStore', {
  state: (): StoreState => ({
    data: [],
    selected: null,
    isLoading: false,
    error: null,
  }),

  actions: {
    async create(clientRecord: IClientRecord) {
      const result = await databases.createDocument(
        'MpuDatabase',

        COLLECTION_IDS.CLIENT_RECORD_COLLECTION,
        'unique()',
        {
          ...clientRecord,
        },
      );

      this.data.push({
        ...clientRecord,
        ...result,
      });
    },

    async loadAll(offset?: number) {
      const result = await databases.listDocuments(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_RECORD_COLLECTION,
        [Query.limit(100), Query.offset(offset ?? 0)],
      );
      const documents = result.documents as unknown as IClientRecordInstance[];

      if (offset) {
        this.data.push(...documents);
      } else {
        this.data = documents;
      }

      if (result.total > this.data.length) {
        const newOffset =
          offset !== undefined ? offset + documents.length : 100;

        this.loadAll(newOffset);
      }
    },

    async loadSingle(id: string) {
      this.selected = (await databases.getDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_RECORD_COLLECTION,
        id,
      )) as unknown as IClientRecordInstance;
    },

    async update(
      id: string,
      clientRecord: IClientRecord,
      permissions?: string[],
    ) {
      await databases.updateDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_RECORD_COLLECTION,
        id,
        omitDocumentKeys(clientRecord),
        permissions,
      );

      const index = this.data.findIndex((record) => record.$id === id);
      this.data[index] = {
        ...this.data[index],
        ...clientRecord,
      };
    },

    async delete(id: string) {
      const result = await databases.deleteDocument(
        'MpuDatabase',
        COLLECTION_IDS.CLIENT_RECORD_COLLECTION,
        id,
      );

      this.data = this.data.filter((record) => record.$id !== id);
    },

    async assignUser(id: string, clientRecord: IClientRecord, userId: string) {
      await executeFunction(FUNCTION_IDS.ASSIGN_USER, {
        userId,
        recordId: id,
        clientRecord: omitDocumentKeys(clientRecord),
      });

      this.loadSingle(id);
    },
  },

  getters: {
    getById: (state) => (id: string) =>
      state.data.find((clientRecord) => clientRecord.$id === id),

    byClientId: (state) => {
      return state.data.reduce((acc, record) => {
        if (!record.clientId) {
          return acc;
        }

        if (!acc[record.clientId]) {
          acc[record.clientId] = [];
        }

        acc[record.clientId].push(record);
        return acc;
      }, {} as { [k: string]: IClientRecordInstance[] });
    },
  },
});
